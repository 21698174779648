import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
  import { newspaper, calendar, ticket, pulse } from 'ionicons/icons';
  
export default /*@__PURE__*/_defineComponent({
  __name: 'TabsPage',
  setup(__props) {

  
return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonTabs), null, {
        default: _withCtx(() => [
          _createVNode(_unref(IonRouterOutlet)),
          _createVNode(_unref(IonTabBar), { slot: "bottom" }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonTabButton), {
                tab: "news",
                href: "/tabs/news"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonIcon), {
                    "aria-hidden": "true",
                    icon: _unref(newspaper)
                  }, null, 8, ["icon"]),
                  _createVNode(_unref(IonLabel), null, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode("News")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_unref(IonTabButton), {
                tab: "schedule",
                href: "/tabs/schedule"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonIcon), {
                    "aria-hidden": "true",
                    icon: _unref(calendar)
                  }, null, 8, ["icon"]),
                  _createVNode(_unref(IonLabel), null, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode("Spielplan")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_unref(IonTabButton), {
                tab: "tickets",
                href: "/tabs/tickets"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonIcon), {
                    "aria-hidden": "true",
                    icon: _unref(ticket)
                  }, null, 8, ["icon"]),
                  _createVNode(_unref(IonLabel), null, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode("Tickets")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_unref(IonTabButton), {
                tab: "liveticker",
                href: "/tabs/livetickers"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonIcon), {
                    "aria-hidden": "true",
                    icon: _unref(pulse)
                  }, null, 8, ["icon"]),
                  _createVNode(_unref(IonLabel), null, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode("Liveticker")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})