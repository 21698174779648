import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { getPlatforms } from '@ionic/vue';
import { onMounted } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

onMounted(() => {
    const platforms = getPlatforms();
    if (platforms.includes('desktop') || platforms.includes('mobileweb')) {
        document.body.classList.add('web-max-width');
    }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonApp), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonRouterOutlet))
    ]),
    _: 1
  }))
}
}

})