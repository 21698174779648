<template>
    <ion-app>
        <ion-router-outlet />
    </ion-app>
</template>

<script setup lang="ts">
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { getPlatforms } from '@ionic/vue';
import { onMounted } from 'vue';

onMounted(() => {
    const platforms = getPlatforms();
    if (platforms.includes('desktop') || platforms.includes('mobileweb')) {
        document.body.classList.add('web-max-width');
    }
});
</script>
